/*
 * Entry point of global application style.
 * Component-specific style should not go here and be included directly as part of the components.
 */
@use '@infomaniak/font-assets/fonts/fonts' as font-assets;

$font-url-products: '/assets/fonts/';
$font-url: "/assets/fonts/";
@import "~@infomaniak/font-assets/manager/icons/style.scss";
@import '~@infomaniak/font-assets/products/_infomaniak-products';
@import "~@infomaniak/font-assets/manager/roboto/font-face";
@import "~@infomaniak/angular-common/src/theme/default/theme";
@import "~@infomaniak/angular-common/src/theme/abstract/components/components";
@import "./theme/common";
@import "assets/sass/flags-phone-input";
@import "assets/sass/my-ksuite";
/* TODO: Refacto colors */

@import "theme/welcome/theme-welcome.scss";

@import "@infomaniak/font-assets/fonts/suisse-intl/suisse-intl.scss";

// Suisse Int\'l
@include font-assets.load-suisse-intl('/assets/fonts/suisse-intl');

body {
  font-family: $suisse-intl-font;
}

*:not([class^='icon-'], [class*=' icon-'], [class^='icon-'] *, [class*=' icon-'] *) {
  font-family: $suisse-intl-font !important;
}

.loader__circle {
  background-color: transparent !important;
}

html,
body {
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;
  min-width: 312px;
}

.superloader {
  height: 100vh;
  width: 100vw;
  position: fixed;
  z-index: 9999;
  top: 0;
  left: 0;
  background-color: #F4F6FD;
  display: flex;
  align-items: center;
  justify-content: center;
}

.disabled {
  cursor: default;
  color: grey;
}

.max-width-100 {
  max-width: 100% !important;
}

@include ik-header-title-theme('light');

:not(.mat-menu-panel), :not(.mat-select-panel), :not(.mat-autocomplete-panel) {
  :not(wc-user-panel-modules) {
    box-shadow: none !important;
  }
}

.mat-menu-panel, .mat-select-panel, .mat-autocomplete-panel {
  box-shadow: 0 2px 2px -1px rgb(0 0 0 / 10%), 0 2px 5px 0 rgb(0 0 0 / 7%), 0 1px 10px 0 rgb(0 0 0 / 6%) !important;
  border: 1px solid rgba(0, 0, 0, .10) !important;
}

// https://github.com/angular/components/issues/7390
html.cdk-global-scrollblock {
  position: initial;
  width: initial;
  overflow: hidden;
}

@include media-breakpoint-down(xs) {
  ::ng-deep .mat-dialog-actions .mat-button-base + .mat-button-base {
    margin-left: 0 !important;
  }
}

.swiper-button-prev:after, .swiper-button-next:after {
  display: none !important;
}
