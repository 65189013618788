/* ------------------ PANEL ------------------ */

@mixin welcome-panel-theme($theme, $colors) {

  .mat-expansion-panel {
    box-shadow: none !important;
    background: mat-color(map-get($theme, background), background);

    .mat-expansion-panel-header {
      border: 1px solid mat-color(map-get($theme, background), separator);

      .mat-expansion-panel-header-title {
        color: mat-color(map-get($theme, foreground), secondary-text);
      }

      .mat-expansion-indicator {
        margin-top: -8px;
      }

      .mat-expansion-indicator:after {
        transform: rotate(45deg) scale(2);
      }
    }

    .mat-expansion-panel-body {
      padding: 30px 0px 0px 0px;
    }
  }
}
