/* ------------------ LAYOUT ------------------ */
@use '@angular/material' as mat;

@mixin welcome-layout-theme($theme, $colors) {
  /*
  * Fix block buttons on mobile
  */
  .block-buttons {
    display: flex;
    align-items: flex-start;

    @include media-breakpoint-down(xs) {
      // position: fixed;
      bottom: 0;
      left: 0;
      // border-top: 1px solid mat-color(map-get($theme, background), list-selected);
      padding: 10px 20px;
      background-color: mat-color(map-get($theme, background), background);
      z-index: 2;
    }

    button:last-child {
      margin: 0;
    }

    &__single {
      flex-direction: column;

      button {
        margin-bottom: 10px;
      }
    }

    &__single--stretched {
      flex-direction: column;
      align-items: stretch;

      button {
        margin-bottom: 10px !important;
      }
    }
  }

  /*
  * Keep bottom space for fixed block buttons on mobile
  */
  .block-buttons-offset-double {
    @include media-breakpoint-down(xs) {
      padding-bottom: 100px;
      display: block;
    }
  }

  app-steps,
  signup-card,
  step-type,
  step-personal-infomation,
  step-address,
  step-credential,
  step-congratz,
  step-select-user,
  step-confirmation-phone,
  step-phone,
  congratz,
  signup-errors,
  one-per-user {
    background-color: mat.get-color-from-palette(map-get($theme, background), background) !important;
  }

  footer {
    background-color: mat.get-color-from-palette(map-get($theme, background), footer) !important;
  }

  /*-------------------
   NOTIFICATION-BANNER
 --------------------*/

  .mat-snack-bar-container.ik-notification-v2 {
    box-shadow: 0px 4px 4px 0px rgba(mat-color(map-get($theme, background), notif-shadow), 0.25) !important;
    border: 1px solid mat-color(map-get($theme, foreground), notif-border);
  }

}
