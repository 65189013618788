/* ------------------ FORM ------------------ */

@mixin welcome-form-theme($theme, $colors) {

  .form {
    &__header {
      h2 {
        color: mat-color(map-get($theme, foreground), text);
        margin-bottom: 15px;
      }

      p {
        margin-bottom: 35px;
        color: mat-color(map-get($theme, foreground), secondary-text);
      }
    }
  }

  /*-------------------
    INPUTS
  --------------------*/

  .ik-form-field.mat-form-field.mat-focused {
    .mat-form-field-outline {
      color: mat-color(map-get($theme, background), primary-blue) !important;
    }

    .mat-form-field-label {
      color: mat-color(map-get($theme, background), primary-blue) !important;
    }
  }

  .ik-form-field.mat-form-field.mat-form-field-invalid {
    .mat-form-field-outline .mat-form-field-label {
      color: mat-color(map-get($theme, foreground), danger) !important;
    }
  }

  .mat-form-field-disabled {
    input {
      color: mat-color(map-get($theme, foreground), text) !important;
      opacity: .5;
    }

    .mat-form-field-outline {
      color: mat-color(map_get($theme, foreground), divider) !important;
    }
  }

  mat-form-field &:not(.mat-form-field-invalid) {
    .mat-form-field-appearance-outline {
      .mat-form-field-outline-thick {
        color: mat-color(map-get($theme, background), primary-blue) !important;
      }
    }
  }

  .mat-focused {
    .mat-form-field-label {
      color: mat-color(map-get($theme, background), primary-blue) !important;
    }
  }

  .mat-form-field.mat-form-field-invalid .mat-form-field-outline,
  .mat-form-field.mat-form-field-invalid .mat-form-field-label,
  .mat-form-field.mat-form-field-invalid .mat-form-field-label .mat-form-field-required-marker,
  .mat-error {
    color: mat-color(map-get($theme, foreground), danger) !important;
  }

  /*-------------------
    CHECKBOX
  --------------------*/
  .mat-checkbox {
    .mat-checkbox-frame {
      border-color: mat-color(map-get($theme, foreground), icon-secondary);
    }

    .mat-checkbox-label {
      white-space: normal;
      line-height: 17px;
    }

    .mat-checkbox-inner-container {
      margin-top: 0;
    }
  }

  .mat-checkbox-checked.mat-primary .mat-checkbox-background {
    background-color: mat-color(map-get($theme, background), primary-blue);
  }

  .mat-hint {
    color: mat-color(map-get($theme, foreground), secondary-text) !important;
  }

  .approvals-checks__checkbox {
    background-color: mat-color(map-get($theme, foreground), divider) !important;
  }

  /*-------------------
    SELECT
  --------------------*/
  .mat-select {
    background-color: mat-color(map-get($theme, background), background-select);

    .mat-select-value {
      color: mat-color(map-get($theme, foreground), secondary-text);
    }
  }

  .comp-select {
    &__container {
      border: 1px solid mat-color(map-get($theme, foreground), divider);
      background-color: mat-color(map-get($theme, background), background);

      &:hover {
        background-color: mat-color(map-get($theme, foreground), divider) !important;
      }
    }
  }


}


