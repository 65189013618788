.my-ksuite-branded-wording {
  white-space: nowrap;
}

.my-ksuite-branded-wording-image {
  vertical-align: bottom;
  height: 1lh;
  width: 1lh;
}

.my-ksuite-branded-wording-image-plus {
  vertical-align: bottom;
  height: 1lh;
  width: auto;
}
