button {
  background: transparent;
  border: none;
  padding: 0;
  font-weight: 400;
  outline: none;

  &:focus {
    outline: none !important;
  }
}

.btn.mat-button,
.btn.mat-flat-button,
.btn.mat-icon-button,
.btn.mat-stroked-button,
.btn.mat-raised-button {
  padding-left: 70px;
  padding-right: 70px;
}

.mat-flat-button.mat-button-base.mat-accent.mat-button-disabled,
.mat-raised-button.mat-button-base.mat-accent.mat-button-disabled,
{
  color: map-get($colors, grey-darken-2);
  background-color: map-get($colors, grey-lighten-2);
}

.link-button.mat-button-base.mat-primary.mat-button-disabled,
{
  color: map-get($colors, grey-darken-2);
}


.block.mat-card-content,
.block {
  margin-bottom: 30px;
}

a {
  text-decoration: none;
}

strong {
  font-weight: 500;
}


.avoid-scrolling {
  max-height: 100%;
  overflow: hidden;
}

// form error
.mat-error {
  color: map-get($colors, red);
}

// header cart icon
.cart__button.mat-badge-medium.mat-badge-overlap.mat-badge-after .mat-badge-content {
  top: 5px;
  height: 20px;
  width: 20px;
  background-color: map-get($colors, pink);
  font-size: 10px;
  color: map-get($colors, color);
  right: -5px;
}

// form radios alignment
.align-radio-label-top.mat-radio-button {
  .mat-radio-label {
    align-items: flex-start;
  }
}

// dialog custom
[class*="dialog--"] {
  width:100vw;
  max-width:100vw;

  .mat-dialog-actions {
    display: block;
    margin: 30px 0 0 0;
    padding: 0;
    min-height: auto;
  }
}

.mat-dialog-container .mat-dialog-actions {
  &.no-padding {
    padding:0;
  }
}

.mat-drawer-container {
  background-color: map-get($colors, grey);
  width:100%;
  height: 100%;
  .mat-drawer-backdrop {
    top: $headerHeight;
  }

  .mat-drawer-content {
    .avoid-scrolling & {
      overflow: unset; // Remove the drawer content scrollbar when the sidebar is open
    }
  }
}

.label {
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
  margin-bottom:10px;
  color: map-get($colors, grey-darken-4);
}


.basic-list {
  padding: 0;
  color: map-get($colors, grey-darken-3);
  margin: 0;
  text-align: left;
  display: block;
  width: 100%;
  list-style: none;
}

.custom-tooltip {
  padding: 12px 13px !important;
  color: map-get($colors, white) !important;
  font-family: "Roboto", sans-serif !important;
  font-size: 14px !important;
  line-height: 21px !important;
  max-width: 276px !important;
  border-radius: 2px !important;
  background-color: rgba(0,0,0,0.9) !important;


  &--compact {
    font-size: 14px !important;
    line-height: 21px !important;
    padding: 10px !important;
    border-radius: 8px !important;
    background-color: map-get($colors, grey-darken-4) !important;
    text-align: start !important;
  }
}

.mat-card {
  font-family: "Roboto", sans-serif !important;
}

// USER SIDEBAR


.menu-header-wrapper {
  min-height: 102px;
  background-color: map-get($colors, grey-lighten-2);
  color: map-get($colors, white);
  -webkit-box-shadow: inset 0 7px 9px -7px rgba(0,0,0,0.4);
  width: 100%;
  -webkit-transition: -webkit-transform 0s;
  transition: -webkit-transform 0s;
  transition: transform 0s;
  transition: transform 0s, -webkit-transform 0s;

  &__user {
    display: block;
  }

  &__collapse {
    background-color: map-get($colors, ui-dark);
    padding:20px;

    &.with-collapse {
      .menu-header-wrapper__arrow {
        -webkit-transform: rotate(90deg);
        transition: transform 0.2s;
      }
    }

  }

  &__submenu {
    position: relative;
    display: block;
    width: 100%;
    cursor: pointer;
    text-align: left;
  }


  &__content {
    margin-left: 15px;
    display: inline-block;
    vertical-align: top;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 80%;
    color: map-get($colors, white);
  }

  &__arrow {
    position:absolute;
    top:3px;
    right:0;
    transition: transform 0.2s;
  }

  &__currentgroup {
    font-size: 14px;
    font-weight: 500;
    color: map-get($colors, white);
    line-height: 20px;
    vertical-align: middle;
    display: inline-block;
  }

}

.menu-header {
  padding: 25px 20px 25px 20px;

  hr {
    width: 33px;
    border: 1px solid #979797;
    opacity: 0.17;
    margin: 0;
    box-sizing: border-box;
    border-radius: 1px;
  }

  &__back {
    position: absolute;
    top: 20px;
    right: 20px;
    color: map-get($colors, white);
  }
  &__name {
    color: map-get($colors, grey-darken-4);
    font-size: 16px;
    line-height: 19px;
  }
  &__mail {
    color: map-get($colors, grey-darken-3);
    font-family: Roboto;
    font-size: 11px;
    line-height: 21px;
  }

  &__avatar {
    border: 1px solid map-get($colors, grey);
    width: 52px;
    height: 52px;
    margin-right: 20px;
    display: inline-block;
    border-radius: 50px;
    overflow: hidden;

    img {
      width: 50px;
      height: 50px;
      display: inline-block;
      max-width: 50px;
    }
    &--content {
      color: map-get($colors, white);
      background-color: map-get($colors, white);
      display: flex;
      flex-direction: column;
      justify-content: center;
      height: 100%;
    }
  }

  &__chevron-icon {
    transition: transform 0.2s;
    &.down {
      -webkit-transform: rotate(90deg);
      transition: transform 0.2s;
    }
  }

  &__rank {
    font-size: 10px;
    line-height: 17px;
    color: map-get($colors, grey-darken-3);
    text-transform: uppercase;
    display: block;
    margin-top: 5px;
  }
}

.menu {

  &__item {
    display: block;
    width: 100%;
  }

  &__link {
    position: relative;
    display: block;
    width: 100%;
    height: 60px;
    line-height: 60px;
    cursor: pointer;
    text-align: left;
    padding: 0 20px;
    color: map-get($colors, grey-darken-3);
    font-size: 14px;

    &:hover {
      text-decoration: none;
      background-color: map-get($colors, grey-lighten-2);
      color: map-get($colors, grey-darken-4);
    }

    &--disabled {
      display: none;
      // color: rgba(0, 0, 0, 0.38);
      // cursor: default;
      // &:hover {
      //   background-color:transparent;
      //   color: rgba(0, 0, 0, 0.38);
      // }
    }
  }

  &__title {
    margin-left: 15px;
    display: inline-block;
    vertical-align: top;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: -webkit-calc(100% - 50px);
    width: calc(100% - 50px);
  }

  &__submenu {
    transition: all 0.35s ease 0s;
    position: relative;
    display: block;
    width: 100%;
    cursor: pointer;
    text-align: left;
    overflow-x: hidden;
    -webkit-overflow-scrolling: touch;

    &.with-collapse {
      height: 0;
      // transition: all 0.35s ease 0s;
    }
  }
}

.block-flex {
  flex-direction: column;
  min-height: 700px;

  @include media-breakpoint-up(md) {
    flex-direction: row;
  }

  &__left {
    position: relative;
    width: 100%;

    @include media-breakpoint-up(md) {
      flex: 0 0 41.66666667%;
      max-width: 41.66666667%;
      &--col-4 {
        flex: 0 0 33.33333333%;
        max-width: 33.33333333%;
      }
    }
  }

  &__right {
    position: relative;
    width: 100%;

    @include media-breakpoint-up(md) {
      flex: 0 0 58.33333333%;
      max-width: 58.33333333%;

      &--col-8 {
        flex: 0 0 66.66666667%;
        max-width: 66.66666667%;
      }
    }
  }
}

.mat-checkbox-layout {
  @include media-breakpoint-down(sm) {
    white-space: normal !important;
  }
}


.mat-snack-bar-container {
  color: map-get($colors, white);

  &.success {
    background-color: map-get($colors, green);
  }

  &.error {
    background-color: map-get($colors, red);
  }
}

// Override check icon

.icon-check.white {
  background: url(/assets/images/ui/check-white.svg);
  width: 15px;
  height: 11px;
  &::before {
    display: none;
  }
}

.icon-check.black {
  background: url(/assets/images/ui/check-black.svg);
  width: 15px;
  height: 11px;
  &::before {
    display: none;
  }
}


.list {

  &__item {
    &:before {
      content: url(/assets/images/ui/check-black.svg) !important;
    }

  }
}



.ik-custom-notification {
  color: #FFFFFF;
  font-family: Roboto;
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
  position: absolute;
  top: 85px;
  right: 20px;
  width: 600px;
  padding-left: 0 !important;

  @include media-breakpoint-down(xs) {
    position: inherit;
  }

  &--success {
    background-color: map-get($colors, success);
  }

  &--warning {
    background-color: map-get($colors, warning);
  }

  &--error {
    background-color: map-get($colors, danger);
  }

  .ik-notification-icon {
    display: none !important;
  }
}


.icon-information {
  color: map-get($colors, grey-darken-4);
}

.icon-remove::before {
  content: "\e92a";
  font-size: 20px;
}




.btn__loader--invisible {
  opacity: 0;
}


.btn-loader {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
}


.avoid-scrolling {
  .support {
    display: none; // Hide Need Help button when the sidebar is open
  }
}

.sm-down-small {
  @include media-breakpoint-down(md) {
    font-size: 11px;
  }
}


/* Hacks IE11 */

@media all and (-ms-high-contrast:none)
{
*::-ms-backdrop, .block-step__steps { justify-content: center !important; }
*::-ms-backdrop, .header__avatar span { margin-top: -10px; }
}


.long-text {
  @include media-breakpoint-down(xs) {
    font-size: 12px !important;
  }
}



// Prevent ios zooming
@media screen and (-webkit-min-device-pixel-ratio:0) {
  select,
  textarea,
  input {
    font-size: 16px !important;
  }
}


.bg-primary-dark {
  background-color: #0070FB;
}

.bg-trial {
  background-color: #ED2C6E;
}
