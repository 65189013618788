/* ------------------ BUTTON ------------------ */

@mixin welcome-button-theme($theme) {

  .mat-flat-button,
  .mat-raised-button {

    &.mat-accent {
      color: mat-color(map-get($theme, foreground), base);
      background-color: mat-color(map-get($theme, foreground), divider);
    }

    &.mat-accent[disabled] {
      color: #9F9F9F;
      background-color: rgb(mat-color(map-get($theme, foreground), divider), 0.5);
    }

    &.mat-primary {
      color: mat-color(map-get($theme, foreground), text-opposite) !important;
      background-color: mat-color(map-get($theme, background), primary-blue);
    }

    &.mat-primary[disabled] {
      background-color: rgb(mat-color(map-get($theme, background), primary-blue), 0.5);
    }

    &:has(> .btn-loader) {
      color: transparent !important;
    }
  }

  .btn {
    &__action {
      padding: 10px 30px;
      text-transform: uppercase;
    }
  }

}
