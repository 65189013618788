/*==========  Defining font sizes  ==========*/
$fontTiny: 11px !default;
$fontSmall: 13px !default;
$fontBase: 14px !default;
$fontMediumSmall: 16px !default;
$fontMedium: 18px !default;
$fontMediumLarge: 22px !default;
$fontLarge: 25px !default;
$fontHuge: 35px !default;
$fontxlHuge: 70px !default;


/*==========  Heights  ==========*/

$header-height: 66px;

$table-header-height: 64px;

$table-row-height: 60px;
$table-row-height-medium: 45px;
$table-row-height-small: 30px;

$list-height: 60px;
$list-height-medium: 45px;
$list-height-small: 30px;


/*==========  Widths  ==========*/
$left-sidebar: 320px;
$left-sidebar-collapsed: 50px;
