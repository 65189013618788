@mixin welcome-tooltip-theme($theme, $colors) {

  .custom-tooltip {
    padding: 12px 13px !important;
    color: map-get($colors, white) !important;
    font-size: 14px !important;
    line-height: 21px !important;
    text-align: left !important;
    max-width: 276px !important;
    border-radius: 4px !important;
    background-color: rgba(0, 0, 0, 0.8) !important;

    strong {
      color: map-get($colors, white);
    }
  }

}
