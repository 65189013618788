@use '@angular/material' as mat;

@mixin initColorsTheme($theme, $color) {
  color: mat.get-color-from-palette(map-get($theme, foreground), base);
  background: mat.get-color-from-palette(map-get($theme, background), footer);

  h1, h2, h3, h4, h5, h6 {
    color: mat.get-color-from-palette(map-get($theme, foreground), base) !important;
  }

  a {
    color: mat-color(map-get($theme, background), primary-blue);

    strong {
      color: mat-color(map-get($theme, background), primary-blue);
    }
  }

  p {
    color: mat.get-color-from-palette(map_get($theme, foreground), base);
  }

  strong {
    color: mat.get-color-from-palette(map-get($theme, foreground), base);
    font-weight: 500;
  }

  .secondary-text {
    color: mat.get-color-from-palette(map-get($theme, foreground), secondary-text) !important;
  }

  .info-box {
    background-color: mat.get-color-from-palette(map-get($theme, foreground), info-box) !important;
  }

  .step__already-exists {
    border-color: mat.get-color-from-palette(map-get($theme, foreground), info-box) !important;
  }

  /* ------------------ BUTTON ------------------ */

  button, .mat-button, .mat-raised-button, .mat-icon-button, .mat-stroked-button, .mat-flat-button, .mat-fab, .mat-mini-fab {
    &.previous {
      .icon {
        color: mat.get-color-from-palette(map-get($theme, foreground), secondary-text);
      }
    }
  }


  .button-circle {
    background: mat.get-color-from-palette(map-get($theme, background), background);

    span.icon {
      color: mat.get-color-from-palette(map-get($theme, foreground), icon);
    }

    &.disable, &:disabled, &[disabled] {
      span.icon {
        color: mat.get-color-from-palette(map-get($theme, foreground), disabled-text);
      }
    }

    &:not(.disable):not(:disabled):not([disabled]):hover {
      background: mat.get-color-from-palette(map-get($theme, accent), default);

      span.icon {
        color: mat.get-color-from-palette(map-get($theme, accent), default-contrast);
      }
    }
  }

  .navigationButtons {
    button {
      border-right-color: mat.get-color-from-palette(map_get($theme, foreground), divider);
    }

    p {
      color: mat.get-color-from-palette(map_get($theme, foreground), secondary-text);
    }
  }


  /* ------------------ BASE ------------------ */

  ik-header {
    border-bottom-color: mat.get-color-from-palette(map_get($theme, foreground), divider);
  }
  ik-left-menu {
    border-right-color: mat.get-color-from-palette(map_get($theme, foreground), divider);
  }
  ik-right-menu {
    border-left-color: mat.get-color-from-palette(map_get($theme, foreground), divider);
  }

  .actionPage {
    .headerPage, .footerPage {
      background: mat.get-color-from-palette(map_get($theme, background), hover);
    }

    .footerPage {
      a {
        color: mat.get-color-from-palette(map-get($theme, accent), default);
      }
    }
  }

  .help-bloc {
    border-color: mat.get-color-from-palette(map-get($theme, foreground), divider);

    h3 {
      background: mat.get-color-from-palette(map-get($theme, background), hover);

      .icon {
        color: mat.get-color-from-palette(map-get($theme, accent), default);
      }
    }

    .help-bloc-content {
      mat-expansion-panel {
        mat-expansion-panel-header {
          background: mat.get-color-from-palette(map-get($theme, background), hover);
        }
      }
    }
  }

  .scroll {
    &:hover {
      &::-webkit-scrollbar-thumb {
        outline-color: mat.get-color-from-palette(map-get($theme, foreground), base);
      }
    }
  }

  .tooltip-arrow {
    border-top: 10px solid mat.get-color-from-palette(map_get($theme, background), background) !important;
  }


  /* ------------------ FORM ------------------ */
  form {
    .mat-form-field-appearance-outline {
      .mat-form-field-outline {
        color: mat.get-color-from-palette(map-get($theme, foreground), divider);
      }

      .mat-form-field-outline-thick {
        color: mat.get-color-from-palette(map-get($theme, foreground), divider);
      }
    }

    .mat-radio-outer-circle {
      border-color: mat.get-color-from-palette(map-get($theme, foreground), divider);
    }

    .mat-radio-label-content {
      line-height: 20px;
    }

    .intl-tel-input {

      input {
        box-shadow: 0 0 0 1px mat.get-color-from-palette(map-get($theme, foreground), divider);

        &:hover {
          box-shadow: 0 0 0 1px mat.get-color-from-palette(map-get($theme, foreground), divider);
        }

        &:focus {
          box-shadow: 0 0 0 2px mat.get-color-from-palette(map-get($theme, accent), default);

          &:hover {
            box-shadow: 0 0 0 2px mat.get-color-from-palette(map-get($theme, accent), default);
          }
        }
      }
    }
  }

  mat-option {
    color: mat.get-color-from-palette(map-get($theme, foreground), secondary-text);
    background-color: mat-color(map-get($theme, background), background-select);
  }

  .mat-accent .mat-option.mat-selected:not(.mat-option-disabled) {
    color: mat-color(map-get($theme, background), primary-blue);
  }

  /* ------------------ AUTOCOMPLETE-CHIPS ------------------ */
  .autochips {
    background-color: mat.get-color-from-palette(map_get($theme, background), hover);
    border: 1px solid mat.get-color-from-palette(map_get($theme, background), hover);

    &--content {
      color: map-get($colors, white);
    }

    .mat-form-field-outline {
      background-color: mat.get-color-from-palette(map_get($theme, background), background);
    }

    chips: {
      background-color: mat.get-color-from-palette(map_get($theme, background), hover);
    }

    .mat-chip {
      background-color: mat.get-color-from-palette(map_get($theme, background), hover);
    }
  }
  .option-display {
    color: mat.get-color-from-palette(map_get($theme, foreground), text);
  }
  .option-value {
    color: mat.get-color-from-palette(map_get($theme, foreground), secondary-text);
  }
  .add {
    border-top-color: mat.get-color-from-palette(map_get($theme, foreground), divider);
  }

  // Hide chrome autocomplete blue background

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus textarea:-webkit-autofill,
  textarea:-webkit-autofill:hover textarea:-webkit-autofill:focus,
  select:-webkit-autofill,
  select:-webkit-autofill:hover,
  select:-webkit-autofill:focus {
    -webkit-box-shadow: 0 0 0 1000px mat.get-color-from-palette(map_get($theme, background), background) inset !important;
    -webkit-text-fill-color: mat.get-color-from-palette(map-get($theme, foreground), base);
  }

  .iti__country-list {
    color: mat.get-color-from-palette(map-get($theme, foreground), base);;
    background-color: mat.get-color-from-palette(map_get($theme, background), background);
  }

}

